.bussCard {
  padding: 0.75rem 1.25rem !important;
  border-radius: 1rem !important;
  font-size: 1.25rem;
  color: white !important;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-out !important;
  opacity: 1;
}
.bussCard:hover {
  opacity: 0.9;
  cursor: pointer;
  text-decoration: underline;
}
.bussCardText {
  letter-spacing: 0.1rem;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
}
.Gradient-platinum {
  background: rgb(0, 58, 70);
  background: linear-gradient(315deg, #003a46 0%, #00d4ff 100%);
  border: 1px solid #00d4ff;
  box-shadow: 0px 7px 8px -4px rgba(0, 58, 70, 1),
    0px 12px 17px 2px rgba(0, 58, 70, 0.9),
    0px 5px 22px 4px rgba(0, 58, 70, 0.8) !important;
}

.Gradient-red {
  background: rgb(70, 0, 0);
  background: linear-gradient(315deg, #460000 0%, #ff0000 100%);
  border: 1px solid #ff0000;
  box-shadow: 0px 7px 8px -4px rgba(70, 0, 0, 1),
    0px 12px 17px 2px rgba(70, 0, 0, 0.9), 0px 5px 22px 4px rgba(70, 0, 0, 0.8) !important;
}
.Gradient-green {
  background: rgb(0, 70, 0);
  background: linear-gradient(315deg, #004600 0%, #00ff00 100%);
  border: 1px solid #00ff00;
  box-shadow: 0px 7px 8px -4px rgba(0, 70, 0, 1),
    0px 12px 17px 2px rgba(0, 70, 0, 0.9), 0px 5px 22px 4px rgba(0, 70, 0, 0.8) !important;
}
.Gradient-blue {
  background: rgb(158, 158, 158);
  background: linear-gradient(315deg, #000046 0%, #0000ff 100%);
  border: 1px solid #0000ff;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 70, 1),
    0px 12px 17px 2px rgba(0, 0, 70, 0.9), 0px 5px 22px 4px rgba(0, 0, 70, 0.8) !important;
}
.Gradient-gold {
  background: rgb(156, 152, 12);
  background: linear-gradient(315deg, #9c980c 0%, #ffff00 100%);
  border: 1px solid #ffff00;
  box-shadow: 0px 7px 8px -4px rgba(156, 152, 12, 1),
    0px 12px 17px 2px rgba(156, 152, 12, 0.9),
    0px 5px 22px 4px rgba(156, 152, 12, 0.8) !important;
}
.Gradient-orange {
  background: rgb(213, 115, 3);
  background: linear-gradient(315deg, #d57303 0%, #ffbc00 100%);
  border: 1px solid #ffbc00;
  box-shadow: 0px 7px 8px -4px rgba(213, 115, 3, 1),
    0px 12px 17px 2px rgba(213, 115, 3, 0.9),
    0px 5px 22px 4px rgba(213, 115, 3, 0.8) !important;
}
.Gradient-gray {
  background: rgb(158, 158, 158);
  background: linear-gradient(315deg, #9e9e9e 0%, #f7f7f7 100%);
  border: 1px solid #f7f7f7;
  box-shadow: 0px 7px 8px -4px rgba(158, 158, 158, 1),
    0px 12px 17px 2px rgba(158, 158, 158, 0.9),
    0px 5px 22px 4px rgba(158, 158, 158, 0.8) !important;
}
.Gradient-gray.bussCard {
  color: #000 !important;
}
.responsiveImg {
  max-width: 100%;
  height: auto;
  border: 1px solid black;
}
.textRight {
  text-align: right;
}
